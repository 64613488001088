import React, { Component } from 'react'
import { SlideDown } from 'react-slidedown'
import { getImage } from 'utils/get-image'

import { processJson } from 'utils'

export default class Accordion extends Component {
    state = {
        openIndices: []
    }

    handleClick = tabIndex => {
        const openIndices = [...this.state.openIndices]

        const index = openIndices.indexOf(tabIndex)

        if (index === -1) {
            openIndices.push(tabIndex)
        } else {
            openIndices.splice(index, 1)
        }

        this.setState({
            openIndices
        })
    }

    render() {
        const {
            tabs,
            colour,
            subColour,
            pageColour,
            learnString,
        } = this.props

        const {
            openIndices
        } = this.state

        return (
            <ul className={'accordion'}>
                {tabs.map((tab, tabIndex) => {
                    const isOpen = openIndices.indexOf(tabIndex) !== -1

                    return (
                        <li className={isOpen ? 'active' : null}>
                            <a onClick={_ => { this.handleClick(tabIndex) }} style={colour}>
                                {tab.title}
                                <img src={getImage('white-down-arrow.png')} />
                            </a>
                            <SlideDown>
                                {isOpen ?
                                    <div className={'content'} style={subColour}>
                                        { processJson(tab.content) }
                                        {tab.links ?
                                            <div className={'learn-more'}>
                                                <p>
                                                    { learnString }
                                                </p>
                                                <ul>
                                                    { tab.links.map((link, linkIndex) => {
                                                            return (
                                                                <li key={linkIndex}>
                                                                    <a 
                                                                        href={link.url} target="_blank"
                                                                        style={{ color: pageColour }}
                                                                    >
                                                                        {link.label}
                                                                    </a>
                                                                </li>
                                                            )
                                                        }) }
                                                </ul>
                                            </div>
                                        : null }
                                        { tab.youtubeVideoId ?
                                            <iframe
                                                className="youtube-video"
                                                width="560" height="360"
                                                src={'https://www.youtube.com/embed/' + tab.youtubeVideoId}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        : null }
                                    </div>
                                    : null}
                            </SlideDown>
                        </li>
                    )
                })}
            </ul>
        )
    }
}