import React, { Component } from 'react'
import './HelplineTable.scss'
import Select from 'react-select'

export default class HelplineTable extends Component {

    state = {
        tabIndex: null,
        currentLanguageIndex: null,
        selectedOption: null,
    }

    handleChange = selectedOption => {
        const index = { selectedOption }.selectedOption.index

        this.setState({
            selectedOption: selectedOption,
            tabIndex: index
        })
    }

    componentDidMount() {
        this.setState({
            tabIndex: null
        })
    }

    render() {

        const {
            tabIndex,
            selectedOption
        } = this.state

        const {
            lines
        } = this.props

        const dropdownOptions = []

        dropdownOptions.push({
            value: null,
            label: 'All (' + lines.length + ')',
            index: null,
        })

        lines.forEach((line, index) => {
            dropdownOptions.push({
                value: line.label.toLowerCase(),
                label: line.label,
                index: index,
            })
        })

        return (
            <div className="helplines-container">
                <h3>
                    Here are some helplines
                </h3>
                <div className="helpline-mobile">
                    <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={dropdownOptions}
                        placeholder={dropdownOptions[0].label}
                    />
                    <ul>
                        {tabIndex == null ? 
                            lines.map((line, lineIndexAll) => (
                                <li key={lineIndexAll}>
                                    <ul>
                                        <li>
                                            <h4>
                                                Services
                                            </h4>
                                            <p>
                                                {line.label}
                                            </p>
                                        </li>
                                        <li>
                                            <h4>
                                                Phone Number
                                            </h4>
                                            <p>
                                                {line.phoneNumber}
                                            </p>
                                        </li>
                                        <li>
                                            <h4>
                                                Website
                                            </h4>
                                            <p>
                                                {line.url}
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            ))
                        : 
                            <li key={lineIndexAll}>
                                <ul>
                                    <li>
                                        <h4>
                                            Services
                                        </h4>
                                        <p>
                                            {lines[tabIndex].label}
                                        </p>
                                    </li>
                                    <li>
                                        <h4>
                                            Phone Number
                                        </h4>
                                        <p>
                                            {lines[tabIndex].phoneNumber}
                                        </p>
                                    </li>
                                    <li>
                                        <h4>
                                            Website
                                        </h4>
                                        <p>
                                            {lines[tabIndex].url}
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        }
                    </ul>
                </div>
                <ul className="helpline-table">
                    <li className="row first">
                        <ul>
                            <li className="column">
                                <h4>
                                    Service
                                </h4>
                            </li>
                            <li className="column">
                                <h4>
                                    Phone Number
                                </h4>
                            </li>
                            <li className="column">
                                <h4>
                                    Website
                                </h4>
                            </li>
                        </ul>
                    </li>
                    {lines.map((line, lineIndex) => (
                        <li key={lineIndex} className="row">
                            <ul>
                                <li className="column">
                                    <p>
                                        {line.label}
                                    </p>
                                </li>
                                <li className="column">
                                    <p>
                                        {line.url}
                                    </p>
                                </li>
                                <li className="column">
                                    <p>
                                        {line.phoneNumber}
                                    </p>
                                </li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}