import React, { Component } from 'react'
import './MaternityTable.scss'
import { StaticQuery, graphql } from 'gatsby'
import { getLanguageIndex } from '../../utils/language';

export default class MaternityTable extends Component {
    isMobile = false

    render() {
        if (typeof window != 'undefined') {
			this.isMobile = window.outerWidth <= 800 ? true : false
		}

        const {
            location,
            data,
            mobileData
        } = this.props

        const currentPath = location.pathname

        const rows = data

        return (
            !this.isMobile ? 
                <ul className="maternity-table">
                    <li>
                        <ul>
                            <li>
                                <h4>
                                    Type of Care
                                </h4>
                            </li>
                            {rows && rows.map((row, typeIndex) => (
                                <li key={typeIndex}>
                                    <h4>
                                        {row.typeOfCare}  
                                    </h4>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li> 
                        <ul>
                            <li>
                                <h4>
                                    Cost
                                </h4>  
                            </li>
                            {rows && rows.map((row, costIndex) => (
                                <li key={costIndex}>
                                    <p>
                                        {row.cost}  
                                    </p>
                                </li>
                            ))}   
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <h4>
                                    Location
                                </h4>
                            </li>
                            {rows && rows.map((row, locationIndex) => (
                                <li key={locationIndex}>
                                    <p>
                                        {row.location}  
                                    </p>
                                </li>
                            ))} 
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <h4>
                                    Pregnancy Education
                                </h4>
                            </li>
                            {rows && rows.map((row, pregnancyIndex) => (
                                <li key={pregnancyIndex}>
                                    <p>
                                        {row.pregnancyEducation}  
                                    </p>
                                </li>
                            ))} 
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <h4>
                                    Support At Time Of Birth
                                </h4>
                            </li>
                            {rows && rows.map((row, supportIndex) => (
                                <li key={supportIndex}>
                                    <p>
                                        {row.supportAtTimeOfBirth}  
                                    </p>
                                </li>
                            ))} 
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <h4>
                                    Location of Birth
                                </h4>
                            </li>
                            {rows && rows.map((row, birthIndex) => (
                                <li key={birthIndex}>
                                    <p>
                                        {row.locationOfBirth}  
                                    </p>
                                </li>
                            ))} 
                        </ul>
                    </li>    
                </ul>
            :
            <img src={mobileData.file.url} />
        )
    }
}

